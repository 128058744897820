/**
 * Template Chooser TinyMCE Plugin modal dialog helper
 */
(function ($, document) {
    var init = function () {
        $('.template-choice').click(function (event) {
            var elem = $(this),
                namespace = elem.data('namespace'),
                index = elem.data('index'),
                fetch_url = wafm_settings_load_template_fetch_url.replace(/__namespace__/g, namespace).replace(/__index__/g, index);

            event.preventDefault();

            $.get(fetch_url, function (data) {
                top.tinymce.activeEditor.setContent(data);
                top.tinymce.activeEditor.windowManager.close();
            });
        });
    };

    $(document).ready(function () {
        init();
    });
})(jQuery, document);
