$(document).ready(function () {
    var init_reply_button = function ($jq_selector) {
        $jq_selector.click(function (event) {
            var noteId = $(this).data('nid'),
                $prototypeHolder = $('#wafm_notes_prototype'),
                url = '',
                $form = '',
                prototype = '',
                $submit = '';

            event.preventDefault();

            url = $prototypeHolder.data('url').replace(/__parentId__/g, noteId);

            prototype = $prototypeHolder.data('prototype');
            $form = $(prototype);

            $submit = $('<a href="#" class="btn btn-primary"><i class="fa fa-save"></i> ' + $prototypeHolder.data('btn') + '</a>');
            $submit.click(function (event) {
                event.preventDefault();

                $.post(url, { text: $form.find('textarea').val() }, function (data) {
                    var $noteChildren = $('#note_' + noteId + '_children');
                    $form.remove();
                    $noteChildren.append(data);
                    init_reply_button($noteChildren.find('.note-reply'));
                });
            });
            $form.append($submit);

            $('#note_new_'+noteId).append($form);
            $('#note_new_'+noteId+' textarea').focus();
        });
    };

    init_reply_button($('.note-reply'));
});
